<template>
  <v-container fluid>
    <v-row v-if="!isFormDraftsPage">
      <v-col cols="12" md="4">
        <SearchBar
          ref="searchBar"
          @search="onSearch($event)"
          searchLabel="Search by form title..."
        />
      </v-col>
      <v-col class="grow" align="right" v-if="$route.name == 'Forms'">
        <FillOutNewDialog />
      </v-col>
      <v-col :class="$route.name != 'Forms' ? 'grow' : 'shrink'" align="right">
        <FormInstancesFilter
          :filters="filters"
          ref="filters"
          @applyFilters="applyFilters($event)"
        />
      </v-col>
      <v-col class="shrink">
        <ExportFormInstances :filters="filters" :options="options" />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title class="msaBlue white--text py-5">
            {{ isFormDraftsPage ? 'Drafts' : 'Forms' }}
            <v-badge
              inline
              class="ml-2"
              bordered
              :content="itemsCount"
              :value="itemsCount"
            >
            </v-badge>
          </v-card-title>
          <v-data-table
            v-model="selectedDrafts"
            class="colored-table-check-box"
            :headers="headers"
            :footer-props="$constants.PAGINATION.DEFAULT_FOOTER_PROPS"
            must-sort
            :items="items"
            :server-items-length="itemsCount"
            :options.sync="options"
            no-data-text="No results found"
            :show-select="isFormDraftsPage"
            checkbox-color="msaBlue"
          >
            <template v-slot:[`item.title`]="{ item }">
              <v-row no-gutters>
                <v-col cols="12">
                  <span
                    @click="goToForm(item)"
                    :style="{ cursor: 'pointer', color: '#3564ac' }"
                  >
                    {{ item.title }}
                  </span>
                </v-col>
                <v-col class="text-caption">
                  {{ item.templateName }}
                </v-col>
              </v-row>
            </template>

            <template v-slot:[`item.projectName`]="{ item }">
              <router-link
                v-if="isClickable"
                :to="{
                  name: 'ProjectDetails',
                  params: { id: item.projectId },
                }"
                class="text-decoration-none"
              >
                {{ item.projectName }}
              </router-link>
              <span v-else>
                {{ item.projectName }}
              </span>
            </template>

            <template v-slot:[`item.createdOnMobileAt`]="{ item }">
              <v-row no-gutters>
                <v-col align="center">
                  {{ item.createdOnMobileAt | filterAsLocalDate }}
                </v-col>
                <v-col cols="12" align="center">
                  by
                  <router-link
                    v-if="isClickable"
                    :to="{
                      name: 'UserDetails',
                      params: { id: item.createdOnMobileBy },
                    }"
                    class="text-decoration-none"
                  >
                    {{ item.createdByName }}
                  </router-link>
                  <span v-else>
                    {{ item.createdByName }}
                  </span>
                </v-col>
              </v-row>
            </template>

            <template v-slot:[`item.updatedAt`]="{ item }">
              <v-row no-gutters>
                <v-col align="center">
                  {{ item.updatedAt | filterAsLocalDate }}
                </v-col>
                <v-col cols="12" align="center">
                  <router-link
                    v-if="isClickable"
                    :to="{
                      name: 'UserDetails',
                      params: { id: item.updatedBy },
                    }"
                    class="text-decoration-none"
                  >
                    by {{ item.updatedByName }}
                  </router-link>
                  <span v-else>
                    {{ item.updatedByName }}
                  </span>
                </v-col>
              </v-row>
            </template>

            <template v-slot:[`item.status`]="{ item }">
              <div
                :class="statusClass(item.formInstanceStatusId)"
                :style="{ 'border-radius': '5px', width: '100px' }"
              >
                {{ item.status }}
              </div>
            </template>

            <template v-slot:[`item.information`]="{ item }">
              <v-row no-gutters>
                <v-col>
                  <div></div>
                </v-col>
                <v-col>
                  <div>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon
                          v-on="on"
                          v-if="item.isPastSync"
                          color="orange"
                          small
                        >
                          mdi-alarm
                        </v-icon>
                      </template>
                      <span>
                        Form has not been updated in the past 8 days and will
                        not be sent to mobile devices.
                      </span>
                    </v-tooltip>
                  </div>
                </v-col>
                <v-col>
                  <div>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon
                          v-on="on"
                          v-if="item.hasUnresolvedCorrectiveActions"
                          color="red"
                          small
                        >
                          mdi-alert-circle
                        </v-icon>
                      </template>
                      <span> Form has unresolved corrective actions. </span>
                    </v-tooltip>
                  </div>
                </v-col>
                <v-col>
                  <div>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon
                          v-on="on"
                          v-if="item.hasResolvedCorrectiveActions"
                          color="green"
                          small
                        >
                          mdi-alert-circle
                        </v-icon>
                      </template>
                      <span> Form has resolved corrective actions. </span>
                    </v-tooltip>
                  </div>
                </v-col>
                <v-col>
                  <div>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon
                          v-on="on"
                          v-if="item.isPrivate"
                          color="msaBlue"
                          small
                        >
                          mdi-lock
                        </v-icon>
                      </template>
                      <span> Form is private. </span>
                    </v-tooltip>
                  </div>
                </v-col>
              </v-row>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <DraftsMassManageDialog
      v-if="isFormDraftsPage && selectedDrafts.length"
      @confirmed="onArchived"
    />
  </v-container>
</template>

<script>
import SearchBar from '@/components/SearchBar.vue';
import ExportFormInstances from '@/components/FormInstances/ExportFormInstances.vue';
import FormInstancesFilter from '@/components/Filters/FormInstancesFilter.vue';
import FillOutNewDialog from '@/components/FormInstances/FillOutNewDialog.vue';
import { phantomAccessCheck } from '../global/functions.js';
import DraftsMassManageDialog from '@/components/FormInstances/DraftsMassManageDialog.vue';

export default {
  name: 'FormsPage',
  components: {
    ExportFormInstances,
    FormInstancesFilter,
    SearchBar,
    FillOutNewDialog,
    DraftsMassManageDialog,
  },
  watch: {
    options: {
      handler(oldVal, newVal) {
        const changed = Object.keys(newVal).some(
          (key) => newVal[key].toString() != oldVal[key].toString(),
        );

        if (changed) {
          this.updateUrl();
        }
      },
      deep: true,
    },
  },
  data() {
    return {
      tags: [],
      users: [],
      projects: [],
      contacts: [],
      items: [],
      itemsCount: 0,
      filters: {
        ...this.$constants.FORM_INSTANCE_DEFAULT_FILTERS,
      },
      options: {
        sortBy: ['createdOnMobileAt'],
        sortDesc: [true],
        page: this.$constants.PAGINATION.DEFAULT_PAGE,
        itemsPerPage: this.$constants.PAGINATION.DEFAULT_PERPAGE,
      },
    };
  },
  methods: {
    getItems() {
      const params = {
        loaderText: 'loading...',
        filters: this.filters,
        options: this.options,
      };
      let url = '';
      if (this.isFormDraftsPage) {
        url = 'get-form-drafts-by-user?format=json';
      } else {
        url = 'get-forms-by-user?format=json';
        if (this.$route.name == 'UserForms') {
          params.userId = this.$route.params.id;
          url = 'get-user-forms?format=json';
        }
      }

      return this.$axios
        .post(url, params)
        .then((response) => {
          this.items = response.data.items;
          this.itemsCount = response.data.count;
          if (this.$route.name == 'UserForms') {
            this.$store.commit('updateSelectedUser', {
              ...response.data.user,
              status: response.data.user.status == 'Active' ? 1 : 0,
            });
          }
        })
        .catch((error) => error);
    },
    applyFilters(filters) {
      this.filters = { ...filters };
      this.options.page = 1;
      this.updateUrl();
    },
    getRouteQueries() {
      const query = this.$route.query;

      this.filters = this.$helpers.deserializeFilters(query, this.filters);
      this.options = this.$helpers.deserializeOptions(
        query,
        'createdOnMobileAt',
        true,
      );

      // default filter doesn't include todo forms
      // we want to include todo forms if user is checking requiring my attention
      if (this.filters.requiringMyAttention) {
        this.filters.status = [2, 3, 4, 5];
      }

      this.getItems();
    },
    updateUrl() {
      let params = this.$helpers.serializeFilter(
        this.filters,
        this.$constants.FORM_INSTANCE_DEFAULT_FILTERS,
      );
      const options = this.$helpers.serializeOptions(
        this.options,
        'createdOnMobileAt',
        true,
      );
      params = { ...params, ...options };

      this.$router
        .replace({ name: this.$route.name, query: params })
        .catch(() => {});
    },
    onSearch(event) {
      this.filters.title = event;
      this.options.page = 1;
      this.updateUrl();
    },
    goToForm(form) {
      this.$store.commit('updateSelectedFormInstance', {
        name: form.title,
      });
      if (this.$route.name == 'UserForms') {
        this.$router.push({
          name: 'CompanyFormDetails',
          params: {
            id: form.id,
          },
        });
      } else {
        this.$router.push({
          name: 'FormDetails',
          params: {
            id: form.id,
          },
        });
      }
    },
    // group admin does not have access to user forms or user drafts page
    checkUserPermission() {
      const isEmployeeRoute =
        this.$route.name == 'Forms' || this.$route.name == 'FormDrafts';
      let pageName = 'Forms';
      if (this.isFormDraftsPage) {
        pageName = 'Drafts';
      }
      if (isEmployeeRoute && !phantomAccessCheck(this, pageName)) {
        return false;
      }
      return true;
    },
    setupSearch() {
      if (this.filters.title != '') {
        this.$refs.searchBar.search = this.filters.title;
      }
      this.$refs.filters.setFilters();
    },
    async onArchived() {
      await this.getItems();
      this.selectedDrafts = [];
    },
  },
  computed: {
    statusClass() {
      return (statusId) => {
        switch (statusId) {
          case 2:
            return 'msaBlue white--text';
          case 3:
            return 'green white--text';
          case 4:
            return 'msaOrange white--text';
          case 5:
            return 'red white--text';
          default:
            return '';
        }
      };
    },
    isWebAdmin() {
      return this.$store.getters.isWebAdmin;
    },
    filterApplied() {
      return (
        JSON.stringify(this.filters) !=
        JSON.stringify(this.$constants.FORM_INSTANCE_DEFAULT_FILTERS)
      );
    },
    headers() {
      const headers = [
        {
          text: 'Title',
          value: 'title',
          align: 'start',
          class: 'headerTextGrey--text lightGrey',
        },
        {
          text: 'Project',
          value: 'projectName',
          align: 'start',
          class: 'headerTextGrey--text lightGrey',
          width: '250px',
        },
        {
          text: 'Created Date',
          value: 'createdOnMobileAt',
          align: 'center',
          class: 'headerTextGrey--text lightGrey',
          width: '200px',
        },
        {
          text: 'Information',
          value: 'information',
          align: 'center',
          class: 'headerTextGrey--text lightGrey',
          sortable: false,
          width: '150px',
        },
      ];

      if (!this.isFormDraftsPage) {
        headers.splice(3, 0, {
          text: 'Last Update',
          value: 'updatedAt',
          align: 'center',
          class: 'headerTextGrey--text lightGrey',
          width: '200px',
        });
        headers.splice(4, 0, {
          text: 'Status',
          value: 'status',
          align: 'center',
          class: 'headerTextGrey--text lightGrey',
          width: '110px',
        });
      }

      return headers;
    },
    isFormDraftsPage() {
      return this.$route.name == 'FormDrafts';
    },
    isClickable() {
      return this.$route.name == 'UserForms';
    },
    persistentSettings: {
      get() {
        return this.$store.getters.persistentSettings['FormDrafts'];
      },
      set(val) {
        this.$store.commit('updatePersistentSettings', {
          key: 'FormDrafts',
          value: val,
        });
      },
    },
    selectedDrafts: {
      get() {
        return this.persistentSettings.selectedDrafts;
      },
      set(value) {
        this.persistentSettings = {
          selectedDrafts: value,
        };
      },
    },
  },
  mounted() {
    if (!this.checkUserPermission()) {
      return;
    }
    this.getRouteQueries();
    this.$nextTick(() => {
      if (!this.isFormDraftsPage) {
        this.setupSearch();
      }
    });
  },
};
</script>

<style></style>
