<template>
  <v-dialog v-model="dialog" width="600" persistent>
    <template v-slot:activator="{ on, attrs }">
      <v-badge
        color="green"
        overlap
        :content="selectedDrafts.length"
        :value="selectedDrafts.length"
        :style="`position: fixed; bottom: 20px; z-index: 80; left: ${floatBtnLeftPosition}px
        }`"
      >
        <v-btn
          elevation="5"
          class="amber lighten-1 msaBlue--text"
          large
          v-bind="attrs"
          v-on="on"
        >
          <v-icon class="pr-2"> mdi-file-document-multiple-outline </v-icon>
          Selected Forms
        </v-btn>
      </v-badge>
    </template>

    <v-card>
      <v-card-title class="msaBlue white--text">
        <v-row>
          <v-col> Mass Archive Drafts </v-col>
          <v-col class="shrink">
            <v-btn icon @click="close()">
              <v-icon color="white">mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text class="pt-4">
        <v-row align="center">
          <v-col cols="12">
            <v-card>
              <v-simple-table>
                <template v-slot:default>
                  <thead class="lightBg">
                    <tr>
                      <th class="text-left">Title</th>
                      <th class="text-right">Remove From Selection</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(formInstance, index) in selectedDrafts"
                      :key="index"
                    >
                      <td>
                        {{ formInstance.title }}
                      </td>
                      <td align="right" :style="{ width: '200px' }">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              small
                              v-on="on"
                              icon
                              color="red"
                              @click="unselect(index)"
                            >
                              <v-icon> mdi-minus-circle-outline </v-icon>
                            </v-btn>
                          </template>
                          Remove from list
                        </v-tooltip>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text dark color="red" @click="cancel()">Cancel</v-btn>
        <v-btn class="msaBlue white--text" @click="confirm()">
          Archive all
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'DraftsMassManageDialog',
  computed: {
    persistentSettings: {
      get() {
        return this.$store.getters.persistentSettings['FormDrafts'];
      },
      set(val) {
        this.$store.commit('updatePersistentSettings', {
          key: 'FormDrafts',
          value: val,
        });
      },
    },
    selectedDrafts: {
      get() {
        return this.persistentSettings.selectedDrafts;
      },
      set(value) {
        this.persistentSettings = {
          selectedDrafts: value,
        };
      },
    },
    floatBtnLeftPosition() {
      return window.innerWidth / 2 - 292 / 2;
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    close() {
      this.dialog = false;
    },
    unselect(index) {
      this.selectedDrafts.splice(index, 1);
    },
    cancel() {
      this.selectedDrafts = [];
      this.close();
    },
    confirm(confirmed = false) {
      if (!confirmed) {
        this.$root.showMessage(
          'IMPORTANT',
          'Please sync your device to remove the archived drafts from your device. Failure to do so will allow you to enter content into an archived form and potentially lose the data.',
          () => this.confirm(true),
          () => false,
          'Confirm',
          'Cancel',
        );
        return;
      }
      const params = {
        loaderText: 'Loading...',
        ids: this.selectedDrafts.map((draft) => draft.id),
      };
      const url = 'archive-drafts?format=json';
      this.$axios
        .post(url, params)
        .then(() => {
          this.$emit('confirmed');
          this.close();
        })
        .catch((error) => error);
    },
  },
};
</script>
