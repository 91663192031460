<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on }">
      <v-btn class="msaBlue white--text" @click="exportData()" v-on="on">
        <v-icon> mdi-download </v-icon>
      </v-btn>
    </template>
    Export
  </v-tooltip>
</template>
<script>
export default {
  name: 'ExportFormInstances',
  props: {
    filters: {
      type: Object,
    },
    options: {
      type: Object,
    },
  },
  methods: {
    exportData() {
      const params = {
        filters: this.filters,
        options: this.options,
        loaderText: 'waiting...',
      };

      let url = '';
      if (this.$route.name == 'CompanyForms') {
        url = 'export-forms-by-company?format=json';
      } else {
        url = 'export-forms-by-user?format=json';
        if (this.$route.name == 'UserForms') {
          params.userId = this.$route.params.id;
          url = 'export-user-forms?format=json';
        }
      }

      const options = {
        responseType: 'blob',
        withCredentials: process.env.VUE_APP_USE_WITH_CREDENTIALS,
      };

      this.$axios
        .post(url, params, options)
        .then((response) => {
          const url = URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'Filled Out Forms Export.xlsx');
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => error);
    },
  },
};
</script>
