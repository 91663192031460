<template>
  <v-dialog v-model="dialog" width="500" persistent>
    <template v-slot:activator="{ on: dialog, attrs }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn
            :color="filterApplied ? 'orange' : 'msaBlue'"
            dark
            v-blur
            v-bind="attrs"
            v-on="{ ...tooltip, ...dialog }"
          >
            <v-icon>mdi-filter</v-icon>
          </v-btn>
        </template>
        Filter
      </v-tooltip>
    </template>
    <v-card>
      <v-footer
        padless
        tile
        class="msaBlue white--text"
        style="position: sticky; top: 0; z-index: 999"
      >
        <v-container fluid>
          <v-row align="center">
            <v-col class="text-h6 pl-6 grow"> Filter Filled Out Forms </v-col>
            <v-col class="shrink pr-6">
              <v-btn
                icon
                @click="
                  dialog = false;
                  thisFilters = { ...filters };
                "
              >
                <v-icon color="white"> mdi-close </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-footer>
      <v-card-text class="mt-4">
        <v-row dense v-if="$route.name == 'CompanyForms'">
          <v-col cols="12" class="pb-0 font-weight-bold">
            Requiring Attention Of
          </v-col>
          <v-col>
            <CustomizedAutoComplete
              :value="thisFilters.requiringAttentionUserIds"
              multiple
              label="Select user(s)"
              :items="users"
              item-text="fullname"
              clearable
              @change="
                thisFilters.requiringAttentionUserIds = $event;
                onRequiringAttentionSelected();
              "
              :disabled="disableReqiuringAttention"
              hint="Note: This filter disables all other filters."
              persistent-hint
            />
          </v-col>
        </v-row>
        <v-row dense v-if="$route.name == 'Forms'" class="pb-2">
          <v-col cols="12">
            <v-checkbox
              v-model="thisFilters.requiringMyAttention"
              :true-value="1"
              :false-value="0"
              hint="Note: This filter disables all other filters."
              persistent-hint
              outlined
              dense
              @change="onRequiringAttentionSelected()"
            >
              <template v-slot:label>
                <span class="font-weight-bold">Requiring My Attention</span>
              </template>
            </v-checkbox>
          </v-col>
        </v-row>
        <v-row dense v-if="!requiringAttentionSelected">
          <v-col cols="12" class="pb-0 font-weight-bold"> Form Title </v-col>
          <v-col class="pb-2">
            <v-text-field
              v-model="thisFilters.title"
              outlined
              hide-details
              placeholder="Enter a name"
              dense
              clearable
              data-testid="form-title-name"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row dense v-if="!requiringAttentionSelected">
          <v-col cols="12" class="pb-0 font-weight-bold"> Form Template </v-col>
          <v-col class="pb-2">
            <v-text-field
              v-model="thisFilters.templateName"
              outlined
              hide-details
              placeholder="Enter a name"
              dense
              clearable
              data-testid="form-template-name"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row dense v-if="!requiringAttentionSelected">
          <v-col cols="12" class="pb-0 font-weight-bold"> Project </v-col>
          <v-col class="pb-2">
            <CustomizedAutoComplete
              deletable-chips
              :value="thisFilters.projectIds"
              multiple
              label="Select project(s)"
              hide-details
              :items="projects"
              clearable
              @change="thisFilters.projectIds = $event"
            />
          </v-col>
        </v-row>
        <v-row
          dense
          v-if="
            !requiringAttentionSelected &&
            $route.name != 'UserForms' &&
            $route.name != 'Forms'
          "
        >
          <v-col cols="12" class="pb-0 font-weight-bold"> Supervisor </v-col>
          <v-col class="pb-2">
            <CustomizedAutoComplete
              deletable-chips
              :value="thisFilters.supervisorIds"
              multiple
              label="Select supervisor(s)"
              hide-details
              :items="supervisors"
              item-text="fullname"
              clearable
              @change="thisFilters.supervisorIds = $event"
            />
          </v-col>
        </v-row>
        <v-row
          dense
          v-if="!requiringAttentionSelected && $route.name != 'UserForms'"
        >
          <v-col cols="12" class="pb-0 font-weight-bold"> Created By </v-col>
          <v-col class="pb-2">
            <CustomizedAutoComplete
              deletable-chips
              :value="thisFilters.createdByIds"
              multiple
              label="Select user(s)"
              hide-details
              :items="users"
              item-text="fullname"
              clearable
              @change="thisFilters.createdByIds = $event"
            />
          </v-col>
        </v-row>
        <v-row dense v-if="!requiringAttentionSelected">
          <v-col cols="12" class="pb-0 font-weight-bold"> Created Date </v-col>
          <v-col class="pb-2" cols="6">
            <v-menu
              v-model="createdFromMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="thisFilters.createdFrom"
                  label="From"
                  readonly
                  outlined
                  dense
                  clearable
                  hide-details
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="thisFilters.createdFrom"
                no-title
                @input="createdFromMenu = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="6">
            <v-menu
              v-model="createdToMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="thisFilters.createdTo"
                  label="To"
                  readonly
                  outlined
                  dense
                  clearable
                  hide-details
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="thisFilters.createdTo"
                no-title
                @input="createdToMenu = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-row
          dense
          v-if="
            !requiringAttentionSelected &&
            $store.getters.isWebAdmin &&
            $route.name != 'UserForms'
          "
        >
          <v-col cols="12" class="pb-0 font-weight-bold">
            Form Assigned To
          </v-col>
          <v-col class="pb-2">
            <CustomizedAutoComplete
              deletable-chips
              :value="thisFilters.assignedToIds"
              multiple
              label="Select user(s)"
              hide-details
              :items="users"
              item-text="fullname"
              clearable
              @change="thisFilters.assignedToIds = $event"
            />
          </v-col>
        </v-row>
        <v-row
          dense
          v-if="!requiringAttentionSelected && $route.name != 'UserForms'"
        >
          <v-col cols="12" class="pb-0 font-weight-bold">
            Signed Off By User
          </v-col>
          <v-col class="pb-2">
            <CustomizedAutoComplete
              deletable-chips
              :value="thisFilters.signedByUserIds"
              multiple
              label="Select user(s)"
              hide-details
              :items="users"
              item-text="fullname"
              clearable
              @change="thisFilters.signedByUserIds = $event"
            />
          </v-col>
        </v-row>
        <v-row
          dense
          v-if="!requiringAttentionSelected && $route.name != 'UserForms'"
        >
          <v-col cols="12" class="pb-0 font-weight-bold">
            Signed Off By Contact
          </v-col>
          <v-col class="pb-2">
            <CustomizedAutoComplete
              deletable-chips
              :value="thisFilters.signedByContactIds"
              multiple
              label="Select contact(s)"
              hide-details
              :items="contacts"
              item-text="fullname"
              clearable
              @change="thisFilters.signedByContactIds = $event"
            />
          </v-col>
        </v-row>
        <v-row dense v-if="!requiringAttentionSelected">
          <v-col cols="12" class="pb-0 font-weight-bold"> Status </v-col>
          <v-col class="pb-2">
            <v-btn-toggle
              v-model="thisFilters.status"
              mandatory
              multiple
              dense
              color="msaBlue"
              style="width: 100%"
              @change="onStatusChanged()"
            >
              <v-btn
                :style="{ width: '25%' }"
                class="text-capitalize"
                :value="5"
              >
                Assigned
              </v-btn>
              <v-btn
                :style="{ width: '25%' }"
                class="text-capitalize"
                :value="2"
              >
                Submitted
              </v-btn>
              <v-btn
                :style="{ width: '25%' }"
                class="text-capitalize"
                :value="4"
              >
                Reviewed
              </v-btn>
              <v-btn
                :style="{ width: '25%' }"
                class="text-capitalize"
                :value="3"
              >
                Finalized
              </v-btn>
            </v-btn-toggle>
          </v-col>
        </v-row>
        <v-row dense v-if="!requiringAttentionSelected">
          <v-col cols="12" class="pb-0 font-weight-bold"> Form Privacy </v-col>
          <v-col class="pb-2">
            <v-btn-toggle
              v-model="thisFilters.isPrivate"
              mandatory
              dense
              color="msaBlue"
              style="width: 100%"
            >
              <v-btn style="width: 33%" class="text-capitalize" :value="2">
                All
              </v-btn>
              <v-btn style="width: 33%" class="text-capitalize" :value="0">
                Public
              </v-btn>
              <v-btn style="width: 33%" class="text-capitalize" :value="1">
                Private
              </v-btn>
            </v-btn-toggle>
          </v-col>
        </v-row>
        <v-row dense v-if="!requiringAttentionSelected">
          <v-col cols="12" class="pb-0 font-weight-bold">
            Past Synchronization Cutoff
          </v-col>
          <v-col class="pb-2">
            <v-btn-toggle
              v-model="thisFilters.pastSyncCutOff"
              mandatory
              dense
              color="msaBlue"
              style="width: 100%"
            >
              <v-btn style="width: 33%" class="text-capitalize" :value="2">
                All
              </v-btn>
              <v-btn style="width: 33%" class="text-capitalize" :value="1">
                Yes
              </v-btn>
              <v-btn style="width: 33%" class="text-capitalize" :value="0">
                No
              </v-btn>
            </v-btn-toggle>
          </v-col>
        </v-row>
        <v-row
          dense
          v-if="!requiringAttentionSelected && $store.getters.isWebAdmin"
        >
          <v-col cols="12" class="pb-0 font-weight-bold">
            Has Form Audit
          </v-col>
          <v-col class="pb-2">
            <v-btn-toggle
              v-model="thisFilters.hasFormAudit"
              mandatory
              dense
              color="msaBlue"
              style="width: 100%"
            >
              <v-btn style="width: 33%" class="text-capitalize" :value="2">
                All
              </v-btn>
              <v-btn style="width: 33%" class="text-capitalize" :value="1">
                Yes
              </v-btn>
              <v-btn style="width: 33%" class="text-capitalize" :value="0">
                No
              </v-btn>
            </v-btn-toggle>
          </v-col>
        </v-row>
        <v-row dense v-if="!requiringAttentionSelected">
          <v-col cols="12" class="pb-0 font-weight-bold">
            Has Corrective Actions
          </v-col>
          <v-col class="pb-2">
            <v-btn-toggle
              v-model="thisFilters.hasCorrectiveActions"
              mandatory
              dense
              color="msaBlue"
              style="width: 100%"
              @change="onHasCorrectiveActionsChanged()"
            >
              <v-btn style="width: 33%" class="text-capitalize" :value="2">
                All
              </v-btn>
              <v-btn style="width: 33%" class="text-capitalize" :value="1">
                Yes
              </v-btn>
              <v-btn style="width: 33%" class="text-capitalize" :value="0">
                No
              </v-btn>
            </v-btn-toggle>
          </v-col>
        </v-row>
        <v-row
          dense
          v-if="
            thisFilters.hasCorrectiveActions != 0 && !requiringAttentionSelected
          "
        >
          <v-col cols="12" class="pb-0 font-weight-bold">
            Corrective Action Status
          </v-col>
          <v-col class="pb-2">
            <v-btn-toggle
              v-model="thisFilters.correctiveActionStatus"
              multiple
              dense
              mandatory
              color="msaBlue"
              style="width: 100%"
            >
              <v-btn style="width: 33%" class="text-capitalize" :value="1">
                Resolved
              </v-btn>
              <v-btn style="width: 33%" class="text-capitalize" :value="2">
                Unresolved
              </v-btn>
            </v-btn-toggle>
          </v-col>
        </v-row>
        <v-row
          dense
          v-if="
            thisFilters.hasCorrectiveActions != 0 &&
            !requiringAttentionSelected &&
            $route.name != 'UserForms'
          "
        >
          <v-col cols="12" class="pb-0 font-weight-bold">
            Corrective Action Assigned To
          </v-col>
          <v-col class="pb-2">
            <CustomizedAutoComplete
              deletable-chips
              :value="thisFilters.correctiveActionAssignedToIds"
              multiple
              label="Select user(s)"
              hide-details
              :items="users"
              item-text="fullname"
              clearable
              @change="thisFilters.correctiveActionAssignedToIds = $event"
            />
          </v-col>
        </v-row>
        <v-row dense v-if="!requiringAttentionSelected">
          <v-col cols="12" class="pb-0 font-weight-bold"> Tags </v-col>
          <v-col>
            <CustomizedAutoComplete
              deletable-chips
              :value="thisFilters.tagIds"
              multiple
              label="Select tag(s)"
              hide-details
              :items="tags"
              item-text="val"
              clearable
              @change="onTagChanged"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-footer
        padless
        tile
        style="position: sticky; bottom: 0; z-index: 999"
        color="msaBlue"
      >
        <v-container fluid>
          <v-row>
            <v-col class="grow" align="right">
              <v-btn class="white red--text" @click="clear()"> clear </v-btn>
            </v-col>
            <v-col class="shrink">
              <v-btn class="white msaBlue--text" @click="apply()">
                apply
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-footer>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'FormInstancesFilter',
  props: {
    filters: {
      type: Object,
    },
  },
  computed: {
    filterApplied() {
      return (
        JSON.stringify(this.filters) !=
        JSON.stringify(this.$constants.FORM_INSTANCE_DEFAULT_FILTERS)
      );
    },
    requiringAttentionSelected() {
      return (
        this.thisFilters.requiringAttentionUserIds.length > 0 ||
        this.thisFilters.requiringMyAttention
      );
    },
    disableReqiuringAttention() {
      return (
        this.thisFilters.title != '' ||
        this.thisFilters.templateName != '' ||
        this.thisFilters.projectIds.length > 0 ||
        this.thisFilters.createdByIds.length > 0 ||
        this.thisFilters.createdFrom != '' ||
        this.thisFilters.createdTo != '' ||
        this.thisFilters.assignedToIds.length > 0 ||
        this.thisFilters.signedByUserIds.length > 0 ||
        this.thisFilters.signedByContactIds.length > 0 ||
        this.thisFilters.status.length < 3 ||
        this.thisFilters.isPrivate != 2 ||
        this.thisFilters.pastSyncCutOff != 2 ||
        this.thisFilters.hasFormAudit != 2 ||
        this.thisFilters.hasCorrectiveActions != 2 ||
        this.thisFilters.correctiveActionStatus.length < 2 ||
        this.thisFilters.correctiveActionAssignedToIds.length > 0 ||
        this.thisFilters.tagIds.length > 0
      );
    },
    supervisors() {
      return this.users.filter((u) => u.isSupervisor);
    },
  },
  watch: {
    dialog(val) {
      if (val && !this.loaded) {
        this.getDataForFilter();
      }
    },
  },
  data() {
    return {
      dialog: false,
      thisFilters: { ...this.$constants.FORM_INSTANCE_DEFAULT_FILTERS },
      createdFromMenu: false,
      createdToMenu: false,
      loaded: false,
      users: [],
      projects: [],
      tags: [],
      contacts: [],
      selectedTags: [],
    };
  },
  methods: {
    onTagChanged(data) {
      //if its prevously selected and there is more than one new value
      if (this.selectedTags.some((i) => i == -1) && data.length > 1) {
        //remove the -1, none
        this.thisFilters.tagIds = data.filter((i) => i != -1);
        this.selectedTags = [...this.thisFilters.tagIds];
        return;
      }
      //if previously not non selected and then selected none
      if (this.selectedTags.some((i) => i != -1) && data.some((i) => i == -1)) {
        //remove all other values
        this.thisFilters.tagIds = data.filter((i) => i == -1);
        this.selectedTags = [...this.thisFilters.tagIds];
        return;
      }
      this.thisFilters.tagIds = data;
      this.selectedTags = [...this.thisFilters.tagIds];
    },
    getDataForFilter() {
      const params = {
        loaderText: 'loading...',
      };
      const url = 'get-data-for-form-filter?format=json';
      this.$axios.post(url, params).then((response) => {
        this.projects = response.data.projects;
        this.tags = response.data.tags;
        this.users = response.data.users;
        this.contacts = response.data.contacts;
        this.loaded = true;
      });
    },
    clear() {
      this.thisFilters = JSON.parse(
        JSON.stringify(this.$constants.FORM_INSTANCE_DEFAULT_FILTERS),
      );
      this.apply();
    },
    apply() {
      this.fixNulls();
      this.$emit('applyFilters', this.thisFilters);
      this.dialog = false;
    },
    fixNulls() {
      if (this.thisFilters.title == null) {
        this.thisFilters.title = '';
      }

      if (this.thisFilters.templateName == null) {
        this.thisFilters.templateName = '';
      }

      if (this.thisFilters.createdFrom == null) {
        this.thisFilters.createdFrom = '';
      }

      if (this.thisFilters.createdTo == null) {
        this.thisFilters.createdTo = '';
      }
    },
    onHasCorrectiveActionsChanged() {
      if (!this.thisFilters.hasCorrectiveActions) {
        this.thisFilters.correctiveActionStatus = [];
        this.thisFilters.correctiveActionAssignedToIds = [];
      } else {
        if (this.thisFilters.correctiveActionStatus.length == 0) {
          this.thisFilters.correctiveActionStatus = [1, 2];
        }
      }
    },
    onRequiringAttentionSelected() {
      this.thisFilters.title = '';
      this.thisFilters.templateName = '';
      this.thisFilters.projectIds = [];
      this.thisFilters.createdByIds = [];
      this.thisFilters.createdFrom = '';
      this.thisFilters.createdTo = '';
      this.thisFilters.assignedToIds = [];
      this.thisFilters.signedByIds = [];
      this.thisFilters.status = [2, 3, 4, 5];
      this.thisFilters.isPrivate = 2;
      this.thisFilters.pastSyncCutOff = 2;
      this.thisFilters.hasFormAudit = 2;
      this.thisFilters.hasCorrectiveActions = 2;
      this.thisFilters.correctiveActionStatus = [1, 2];
      this.thisFilters.correctiveActionAssignedToIds = [];
      this.thisFilters.tagIds = [];
      this.selectedTags = [];
    },
    onStatusChanged() {
      this.thisFilters.status.sort((a, b) => a - b);
    },
    setFilters() {
      this.thisFilters = { ...this.filters };
      this.selectedTags = [...this.thisFilters.tagIds];
    },
  },
};
</script>

<style lang="scss" scoped>
.v-select::v-deep .v-chip {
  /* chip background color msaBlue */
  background-color: #3564ac;

  /* chip text color */
  color: white;

  /* chip X color */
  button {
    color: white;
  }
}
</style>
